<template>
<div>
  <!----- 搜索框 start------->
  <el-form :inline="true" class="search">
    <el-form-item label="名称">
      <el-input v-model="searchData.name" placeholder="名称" clearable />
    </el-form-item>
    <el-form-item>
      <el-button @click="getPackages()">查询</el-button>
    </el-form-item>
    <el-form-item style="float: right">
      <el-button type="success" @click="clickCreate()">添加套餐</el-button>
    </el-form-item>
  </el-form>
  <!----- 搜索框 end------->

  <!----- 表格 start------->
  <el-table :data="tableData" :border="true" empty-text="暂无数据" style="width: 100%">
    <el-table-column prop="id" label="ID"  />
    <el-table-column prop="name" label="套餐名" />
    <el-table-column prop="level_desc" label="等级" />
    <el-table-column prop="expire_day" label="有效天数" />
    <el-table-column prop="price" label="价格" />
    <el-table-column prop="sort" label="排序" />
    <el-table-column label="状态">
      <template #default="scope">
        <el-switch
            :active-value="1"
            :inactive-value="2"
            @change="changeStatus(scope.row)"
            :model-value="scope.row.status">
        </el-switch>
      </template>
    </el-table-column>
    <el-table-column prop="created_at" label="创建时间" />
    <el-table-column label="操作">
      <template #default="scope">
        <el-button type="primary" size="small" @click="clickUpdate(scope.row)">修改</el-button>
      </template>
    </el-table-column>
  </el-table>
  <!----- 表格 end------->

  <!----- 分页 start------->
  <div class="cherry-page" v-show="searchData.total">
    <el-pagination
        small
        background
        layout="prev, pager, next, total"
        :total="searchData.total"
        :page-size="searchData.per_page"
        v-model:current-page="searchData.page"
        @update:current-page="getPackages()"
        class="mt-4"
    />
  </div>
  <!----- 分页 end------->

  <!-------- 弹出框表单 start ---------->
  <el-dialog v-model="formData.show" title="新增套餐" append-to-body>
    <el-form
        ref="package"
        :rules="formData.rules"
        label-width="100px"
        :model="formData.data"
    >
      <el-form-item label="名称" prop="name">
        <el-input v-model="formData.data.name" placeholder="输入展示名"/>
      </el-form-item>
      <el-form-item label="金额" prop="price">
        <el-input type="number" v-model="formData.data.price" placeholder="输入售卖金额"/>
      </el-form-item>
      <el-form-item label="有效期" prop="expire_day">
        <el-input type="number" v-model="formData.data.expire_day" placeholder="有效天数"/>
      </el-form-item>
      <el-form-item label="等级" prop="level">
        <el-select v-model="formData.data.level" >
          <el-option label="会员" :value="2"></el-option>
          <el-option label="企业会员" :value="3" disabled></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="submit">提交</el-button>
        </span>
    </template>
  </el-dialog>
  <!-------- 弹出框表单 end ---------->
</div>
</template>

<script>
import {apiGetPackages, apiSettingPackage, apiCreatePackage, apiUpdatePackage} from '@/api/packageApi'
  export default {
    created() {
      this.getPackages()
    },
    data () {
      return {
        searchData:{
          name: '',
          per_page: 100,
        },
        formData: {
          show: false,
          data: {
            id: 0,
            name: '',
            level: 2,
            price: '',
            expire_day: ''
          }
        },
        tableData:[],
      }
    },
    methods:{
      getPackages () {
        apiGetPackages(this.searchData).then(res => {
          this.tableData = res
        })
      },
      // 改变状态
      changeStatus (row) {
        apiSettingPackage(row.id).then(() =>{
          this.$message.success('操作成功')
          this.getPackages()
        })
      },
      clickUpdate (row) {
        this.formData.data = row
        this.formData.show = true
      },
      // 创建合同
      clickCreate() {
        this.formData.data = {
          id: 0,
          name: '',
          level: 2,
          price: '',
          expire_day: ''
        }
        this.formData.show = true
      },
      closeDialog () {
        this.formData.show = false
      },
      submit() {
        if (this.formData.data.id) {
          apiUpdatePackage(this.formData.data.id, this.formData.data).then(() =>{
            this.closeDialog()
            this.$message.success('修改成功')
            this.getPackages()
          })
        } else {
          apiCreatePackage(this.formData.data).then(() =>{
            this.closeDialog()
            this.$message.success('修改成功')
            this.getPackages()
          })
        }
      }
    }
  }
</script>

<style scoped>

</style>
