import {httpGet, httpPost, httpPut} from '@/plugins/request'

/**
 * 获取套餐列表
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetPackages(query = {})
{
    return httpGet('/admin/package', query)
}

/**
 * 设置套餐状态
 *
 * @param id
 * @param query
 * @returns {Promise<*>}
 */
export function apiSettingPackage(id, query ={}) {
    return httpPost(`/admin/package/${id}/setting`, query)
}

/**
 * 新套更新
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiUpdatePackage(id, data ={}) {
    return httpPut(`/admin/package/${id}`, data)
}


/**
 * 新增套餐
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiCreatePackage(data ={}) {
    return httpPost(`/admin/package`, data)
}

